import React from "react"
import "./Process.scss"

import Article from "../Article/ArticleRemake"

import bG from "../../assets/img/Process/1.png"
import ProcessArticle1 from "../../assets/img/Process/Process_1.jpg"
import ProcessArticle2 from "../../assets/img/Process/Process_2.jpg"

const processData = [
  {
    title: "Re-imagine Your Process",
    description: [
      "Revolutionize the way you work. Our goal is to help you be more effective, more creative and push your business further than you thought possible. Our solutions have changed the way companies approach working. Whether that be through bespoke machine learning algorithms, a custom computer vision solution or a tailored forecasting tool, you can be confident we will find the right solution to make an impact.",
    ],
    imgLink: ProcessArticle1,
  },
  {
    title: "Leverage AI Through Personal Interactions",
    description: [
      `Your problems are our problems. Our people-first approach allows us to understand your business and its challenges intimately. We strive to implement solutions that add real value, discover useful insights and make your business more cost effective.`,
    ],
    imgLink: ProcessArticle2,
  },
]

export default function Process() {
  return (
    <section className="process">
      <div
        className="article-section wrapper"
        style={{ backgroundImage: `url(${bG})` }}
      >
        {processData.map((item, index) => {
          return (
            <Article
              title={item.title}
              description={item.description}
              index={index}
              imgLink={item.imgLink}
              distance={false}
              showArticleImgHidden={true}
            />
          )
        })}
      </div>
    </section>
  )
}
