import React from "react"
import PropTypes from "prop-types"
// import { useTranslation } from "gatsby-plugin-react-i18next"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import "./ArticleRemake.scss"

ArticleRemake.propTypes = {
  subject: PropTypes.string,
  title: PropTypes.string.isRequired,
  isShowLine: PropTypes.bool,
  description: PropTypes.string,
  color: PropTypes.bool,
  index: PropTypes.number,
  imgLink: PropTypes.string,
  distance: PropTypes.bool,
  showSpecialType: PropTypes.bool,
  showArticleImgHidden: PropTypes.bool,
}

ArticleRemake.defaultProps = {
  isShowLine: true,
  color: true,
  distance: true,
  showSpecialType: false,
  showArticleImgHidden: false,
}

export default function ArticleRemake(props) {
  const {
    title,
    isShowLine,
    description,
    index,
    subject,
    color,
    imgLink,
    distance,
    showSpecialType,
    showArticleImgHidden,
  } = props

  // const { t } = useTranslation()
  return (
    <div className="article-remake">
      {index % 2 === 0 || showSpecialType ? (
        <>
          <div className="row mg custom">
            <div className="article-remake__textBox article-remake__textBox__normal">
              <HandleOrphan>
                <h2
                  className=" fix-orphan article-remake__textBox__subject"
                  style={{
                    color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                  }}
                >
                  {subject}
                </h2>
              </HandleOrphan>
              {title && (
                <HandleOrphan>
                  <h2
                    className=" fix-orphan article-remake__textBox__title"
                    style={{
                      color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                    }}
                  >
                    {title}
                  </h2>
                </HandleOrphan>
              )}
              {isShowLine && (
                <div className="article-remake__textBox__line"></div>
              )}
              {description && (
                <HandleOrphan>
                  <p
                    className=" fix-orphan article-remake__textBox__description"
                    style={{
                      color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                    }}
                  >
                    {description}
                  </p>
                </HandleOrphan>
              )}
            </div>
            <div
              className="article-remake__distance"
              style={{ display: `${distance ? "none" : ""}` }}
            ></div>

            {imgLink && (
              <div className="article-remake__imgBox ">
                <div className="article-remake__imgBox__picBox">
                  <img className="changePosition" src={imgLink} alt={title} />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="row custom">
            {imgLink && (
              <div className="article-remake__imgBox article-remake__imgBox__before ">
                <div className="article-remake__imgBox__picBox">
                  <img className="changePosition" src={imgLink} alt={title} />
                </div>
              </div>
            )}
            <div
              className="article-remake__distance"
              style={{ display: `${distance ? "none" : ""}` }}
            ></div>
            <div className="article-remake__textBox article-remake__textBox__special">
              <HandleOrphan>
                <h2
                  className=" fix-orphan article-remake__textBox__subject"
                  style={{
                    color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                  }}
                >
                  {subject}
                </h2>
              </HandleOrphan>
              {title && (
                <HandleOrphan>
                  <h2
                    className=" fix-orphan article-remake__textBox__title"
                    style={{
                      color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                    }}
                  >
                    {title}
                  </h2>
                </HandleOrphan>
              )}
              {isShowLine && (
                <div className="article-remake__textBox__line"></div>
              )}
              {description && (
                <HandleOrphan>
                  <p
                    className=" fix-orphan article-remake__textBox__description"
                    style={{
                      color: `${color ? "var(--white)" : "var(--black-blue)"}`,
                    }}
                  >
                    {description}
                  </p>
                </HandleOrphan>
              )}
            </div>
            {imgLink && showArticleImgHidden && (
              <div className="article-remake__imgBox article-remake__imgBox__after">
                <div className="article-remake__imgBox__picBox">
                  <img className="changePosition" src={imgLink} alt={title} />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
